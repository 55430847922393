import React, { useState, useContext, useEffect } from "react";
import Context from "../context";
import Logo from "../assets/logo.svg";
import { Form, PasswordConditions, ErrorBox } from "../components";

const Login = ({ location, match }) => {
  const { api, state } = useContext(Context);

  // if token is present this is a client sign up
  const [inviteToken, setInviteToken] = useState(null);
  useEffect(() => {
    if (match.params.token) {
      setInviteToken(match.params.token);
      api.getInvite(match.params.token);
    }
  }, []);

  const [password, setPassword] = useState("");
  const [passwordCopy, setPasswordCopy] = useState("");

  async function onSubmit(params) {
    if (PasswordConditions.allConditionsPass(password, passwordCopy)) {
      params.password = password;
      api.createUser(params);
    }
  }

  return (
    <div className="font-move place-content-center h-screen w-screen">
      <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-8 mb-6 w-auto" src={Logo} alt="Move Health Partners" />
          <h2 className="mt-10 text-center text-2xl font-semibold leading-9 tracking-tight text-move-dslate">
            Create Your Account
          </h2>
        </div>

        <div className="mb-2 sm:mx-auto sm:w-full sm:max-w-sm">
          <ErrorBox errors={state.errors.users} />
          <Form
            className="space-y-6"
            onSubmit={onSubmit}
            submitCopy="Sign Up"
            type="login"
            col="12"
            inputs={[
              {
                type: "hidden",
                name: "invite_token",
                defaultValue: inviteToken,
              },
              {
                label: "Email",
                type: "email",
                name: "email",
                placeholder: "Email",
                defaultValue: state.user.email,
              },
            ]}
          >
            <div className="col-md-12">
              <div className="input-primary">
                <label>Set password</label>
                <input
                  value={password}
                  onChange={({ target }) => setPassword(target.value)}
                  type="password"
                  placeholder="••••••••"
                />
              </div>
              <div className="input-primary">
                <label>Confirm password</label>
                <input
                  value={passwordCopy}
                  onChange={({ target }) => setPasswordCopy(target.value)}
                  type="password"
                  placeholder="••••••••"
                />
              </div>
              <PasswordConditions password={password} passwordCopy={passwordCopy} />
            </div>
          </Form>
          <p className="mt-2 text-sm text-move-dslate block text-center">
            By continuing you agree to our{" "}
            <a
              href="https://movehealth.io/terms"
              className="text-move-teal hover:text-move-teal-600 no-underline font-bold"
            >
              Terms of Service
            </a>
            .
          </p>
          <p className="mt-12 text-center text-sm text-move-slate">
            Already have an account?{" "}
            <a
              href="/login"
              className="font-semibold leading-6 text-move-orange hover:text-move-orange-600 no-underline"
            >
              Sign In
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
