import Cal, { getCalApi } from "@calcom/embed-react";
import React, { useEffect } from "react";
import { withStuff } from "../hocs";

const Calendar = ({ user, api, advisorView = false }) => {
  const meetingTypes = {
    pre_65: "pre65",
    medicare: "medicare",
    combo: "dualconsultation",
  };

  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal("ui", {
        styles: {
          branding: {
            brandColor: "#F16028",
          },
        },
        hideEventTypeDetails: false,
        layout: "month_view",
      });

      cal("on", {
        action: "bookingSuccessful",
        callback: async (e) => {
          const data = e.detail.data;
          await api.updateUser(user.id, {
            booking_id: data.booking.uid,
          });

          if (advisorView) {
            window.location.href = `/clients/${user.id}`;
          } else {
            window.location.href = "/";
          }
        },
      });
    })();
  }, []);

  return (
    <Cal
      calLink={
        (user.health_advisor?.meeting_path || "team/move-health-partners") +
        "/" +
        meetingTypes[user.client_request_type]
      }
      style={{
        width: "100%",
        height: "100%",
        overflow: "scroll",
        zIndex: 0,
      }}
      config={{
        layout: "month_view",
        name: user.name,
        email: user.email,
      }}
    />
  );
};

export default withStuff(Calendar, {
  api: true,
});
