import React from "react";

const Pagination = ({
  currentPage,
  totalCount,
  pageSize,
  onPageChange,
  onPageSizeChange,
  type = "client",
}) => {
  console.log("totalCount passed to Pagination", totalCount);
  const totalPages = Math.ceil(totalCount / pageSize);
  const handlePageChange = (page) => {
    // if (page < 1 || page > totalPages) return;
    onPageChange(page);
  };

  return (
    <div className="flex justify-between items-center space-x-2 text-sm">
      <div className="flex items-center">
        <div className="mr-1">Items per page:</div>
        <select
          value={pageSize}
          onChange={(e) => onPageSizeChange(parseInt(e.target.value))}
          className="p-1 border rounded w-12 appearance-auto"
          style={{ appearance: "auto" }}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
      <div>
        <button
          className="px-3 py-1 bg-gray-200 rounded"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="px-3 py-1 bg-gray-200 rounded"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      <div>
        Total {type[0].toUpperCase() + type.slice(1)}s: {totalCount}
      </div>
    </div>
  );
};

export default Pagination;
