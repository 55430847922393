import React from "react";
import { withStuff } from "../hocs";
import { ClientIndex, ActivityIndex, TodoIndex, Breadcrumb } from "../components";

const Dashboard = ({ state }) => {
  return (
    <div className="font-move mt-3 mx-3 xl:mx-0 ml-5 xl:ml-0">
      <Breadcrumb self="Dashboard" />
      <TodoIndex todos={state.user.todos} userType={state.user.type} />
      <div className="xl:flex gap-3 mt-12 items-start">
        <div className="w-full xl:w-2/3">
          <ClientIndex pagination={false} header="Recent Engagements" search={false} />
        </div>
        <div className="w-full xl:w-1/3 mt-6 xl:mt-0">
          <ActivityIndex activities={state.activities} />
        </div>
      </div>
    </div>
  );
};

export default withStuff(Dashboard, {
  state: true,
});
