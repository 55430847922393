import React, { useState, useEffect, useRef } from "react";
import { Table, Breadcrumb, CopyButton, Inputs, Pagination } from "../components";
import { withStuff } from "../hocs";

const EstimationsIndex = ({ state, header = "All Estimates", search = false }) => {
  const estimations = state.estimations || [];
  const totalCount = estimations?.totalCount;
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [order, setOrder] = useState({
    field: "name",
    order: "asc",
  });
  const hasMounted = useRef(false);

  useEffect(() => {
    if (hasMounted.current) {
      const handler = setTimeout(() => {
        const params = {
          pageSize: limit,
          currentPage: page,
          ...order,
        };
        if (searchTerm) params.search = searchTerm;

        api.getEstimations(params);
      }, 300);

      return () => {
        clearTimeout(handler);
      };
    } else {
      hasMounted.current = true;
    }
  }, [searchTerm, page, order, limit]);

  useEffect(() => {
    setPage(1);
  }, [limit]);

  const schema = {
    name: {
      descending: order.order === "desc",
      onClick: () => {
        setOrder({
          field: "name",
          order: order.order === "desc" ? "asc" : "desc",
        });
      },
      children: ({ datum }) => {
        return (
          <a className="no-underline" href={`/estimations/${datum.id}`}>
            <div className="text-move-green text-md font-semibold">{datum.name}</div>
          </a>
        );
      },
    },
    type: {
      label: "Type",
    },
    dependents_count: {
      label: "Applicants",
    },
    updated_at: {
      label: "Last Updated",
      descending: order.order === "desc",
      onClick: () => {
        setOrder({
          field: "updated_at",
          order: order.order === "desc" ? "asc" : "desc",
        });
      },
      children: ({ datum }) => {
        const date = new Date(datum.updated_at);
        return (
          <div>
            {date.toLocaleString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
          </div>
        );
      },
    },
    created_at: {
      label: "Created",
      onClick: () => {
        setOrder({
          field: "created_at",
          order: order.order === "desc" ? "asc" : "desc",
        });
      },
      children: ({ datum }) => {
        const date = new Date(datum.created_at);
        return (
          <div>
            {date.toLocaleString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
          </div>
        );
      },
    },
    actions: {
      label: "Actions",
      children: ({ datum }) => {
        return (
          <div className="flex justify-between items-center">
            <div className="space-x-4">
              <a
                onClick={() => {
                  api.duplicateEstimation(datum.id);
                }}
              >
                <button className="btn-icon">
                  <i className="fa-regular fa-clone"></i> Duplicate
                </button>
              </a>
              <CopyButton
                copyText={datum.share_url}
                preText="Copy Share Link"
                postText="Link Copied!"
              />
            </div>
            <a
              className="text-red-500 hover:text-red-700 cursor-pointer ml-4"
              onClick={() => {
                if (window.confirm("Are you sure you want to delete this estimation?"))
                  api.destroyEstimation(datum.id);
              }}
            >
              <span className="text-xs">
                <i className="fa-solid fa-x" />
              </span>
            </a>
          </div>
        );
      },
    },
  };

  const handlePageChange = (page) => {
    console.log("page", page);
    setPage(page);
  };

  return (
    <div className="font-move mt-3 mx-3 xl:mx-0 ml-5 xl:ml-0 ronu">
      <Breadcrumb self="Estimator" />
      <div className="mt-12 w-full rounded-md shadow-sm bg-move-lgrey p-3">
        <div className="flex justify-between items-start">
          <span className="text-lg font-medium">{header}</span>
          <a
            className="rounded-md bg-move-orange text-move-white text-sm font-bold hover:shadow-md hover:text-move-lslate px-3 py-2"
            href="/estimations/new"
          >
            <button type="button">
              <i className="fa-solid fa-circle-plus mr-2"></i>New Estimate
            </button>
          </a>
        </div>
        <div className="mt-3">
          <Inputs
            col="12"
            inputs={[
              {
                type: "text",
                name: "search",
                placeholder: "Search",
                style: { marginTop: "0px" },
                onChange: (e) => {
                  setSearchTerm(e.target.value);
                },
              },
            ]}
          />
        </div>
        {state.estimations?.length === 0 ? (
          <div className="text-center my-5">
            <p>No estimations yet.</p>
          </div>
        ) : (
          <Table schema={schema} data={estimations || []} paginated={true} />
        )}
        <Pagination
          type="estimation"
          currentPage={page}
          totalCount={totalCount}
          pageSize={limit}
          onPageChange={handlePageChange}
          onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
        />
      </div>
    </div>
  );
};

export default withStuff(EstimationsIndex, {
  state: true,
  api: true,
  // loader: "estimations",
  effect: async ({ api, limit, page, order }) => {
    const params = {
      pageSize: 25,
      currentPage: 1,
      field: "name",
      order: "asc",
    };
    api.getEstimations(params);
  },
});
