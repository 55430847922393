import React, { useState } from "react";

const CopyButton = ({ copyText, preText, postText, className = "btn-icon", style }) => {
  const [copied, setCopied] = useState(false);

  const copy = () => {
    navigator.clipboard.writeText(copyText);
    setCopied(true);
  };

  return (
    <button onClick={copy} className={className} style={style}>
      {copied ? (
        postText
      ) : (
        <>
          <i className="fa-regular fa-copy"></i> {preText}
        </>
      )}
    </button>
  );
};

export default CopyButton;
