import React, { useState } from "react";
import { withStuff } from "../hocs";
import { Coupon, Loader, PaymentMethodForm } from ".";
import CancelSubscription from "./cancel_subscription";
import { toUSD } from "../utils";

const PaymentPlan = ({ state, api, afterUpdate, onboarding = false, token = false }) => {
  const [edit, setEdit] = useState(false);
  const [paymentPlanLoading, setPaymentPlanLoading] = useState(false);
  const payment_method = state.account?.payment_method || {};
  const plan_type = state.account?.plan_type;

  async function handlePaymentUpdate(id) {
    const res = await api.attachPaymentMethod(id);
    if (res) {
      setEdit(false);
      afterUpdate && afterUpdate();
    }
  }

  async function handlePlanUpdate(plan_type) {
    setPaymentPlanLoading(true);
    const res = await api.updateAccount({ plan_type }, state.account.id);
    setPaymentPlanLoading(false);
  }

  function applyDiscount(price) {
    if (state.account.applied_coupon_amount) {
      return price - state.account.applied_coupon_amount / 100;
    } else if (state.account.applied_coupon_percent) {
      return price - price * (state.account.applied_coupon_percent / 100);
    } else {
      return price;
    }
  }

  function amountOrPercentageOff(account) {
    if (account.applied_coupon_amount) {
      return `${toUSD(account.applied_coupon_amount / 100)} off`;
    } else {
      return `${account.applied_coupon_percent}% off`;
    }
  }

  return (
    <div className="mb-4">
      <div className="text-lg font-medium mb-3">Billing Frequency</div>
      <div className="divide-y divide-move-lslate">
        {state.account.has_coupon && (
          <span className="text-sm mb-2 inline-block font-bold px-3 py-2 text-move-dgreen bg-move-lgreen rounded-full">
            <strong>{state.account.coupon_id}</strong> - {amountOrPercentageOff(state.account)}
          </span>
        )}
        <div>
          {paymentPlanLoading ? (
            <div>
              <Loader />
            </div>
          ) : (
            <div className={`${onboarding ? "" : "flex"} flex-col xl:flex-row gap-3 mb-6`}>
              <div
                onClick={() => {
                  if (window.confirm("Are you sure you want to change your payment plan?")) {
                    handlePlanUpdate("starter");
                  }
                }}
                className={`${
                  onboarding ? "mb-4" : ""
                } flex flex-1 flex-col max-w-xl gap-2 rounded p-3 cursor-pointer ${
                  plan_type == "annual" || plan_type == "monthly" || plan_type == "starter"
                    ? "border-move-orange border-2"
                    : "border"
                }`}
              >
                <div className="flex justify-between">
                  <div className="font-semibold text-lg">Starter</div>
                </div>
                <div className="flex flex-col justify-start gap-2 mt-1">
                  <div className="text-sm mb-3 text-left">
                    Starter is the right fit for an advisor who plans to engage with Move Health on
                    5-10 clients per year. This includes our healthcare cost estimator, engagement
                    dashboard and our advisor resource library.
                  </div>
                  <div className="flex text-sm items-center">
                    <div className="font-semibold">Advisor Seat</div>
                    <div className="flex flex-grow mx-2 h-0 border-b-2 border-dotted border-move-lslate"></div>
                    <div>${applyDiscount(550)}/user per yr.</div>
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  if (window.confirm("Are you sure you want to change your payment plan?")) {
                    handlePlanUpdate("core");
                  }
                }}
                className={`flex flex-1 flex-col max-w-xl gap-2 rounded p-3 cursor-pointer ${
                  plan_type == "core" ? "border-move-orange border-2" : "border"
                }`}
              >
                <div className="flex">
                  <div className="font-semibold text-lg">Core</div>
                </div>
                <div className="flex flex-col justify-start gap-2 mt-1">
                  <div className="text-sm mb-3 text-left">
                    Core is the right fit for advisors who have made healthcare planning a proactive
                    part of their financial planning process. Core includes all features of Starter
                    and also includes our proprietary HealthPlanner™ technology for you and your
                    clients.
                  </div>
                  <div className="flex text-sm items-center">
                    <div className="font-semibold">Advisor Seat</div>
                    <div className="flex flex-grow mx-3 h-2 border-b-2 border-dotted border-move-lslate"></div>
                    <div>${applyDiscount(750)}/user per yr.</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Coupon />
        <div>
          {edit || !payment_method?.id ? (
            <div>
              <div className="text-lg font-medium mt-6 mb-3">Enter Payment Method</div>
              <div className="w-full card p-3">
                <PaymentMethodForm onSubmit={handlePaymentUpdate} token={token} api={api} />
              </div>
            </div>
          ) : (
            <div className="w-full card mt-6 p-3 cursor-pointer" onClick={() => setEdit(true)}>
              <div className="flex justify-between">
                <div className="flex justify-start gap-1">
                  <div className="capitalize font-semibold">{payment_method.brand}</div>
                  <div>credit card ending in</div>
                  <div className="font-semibold">{payment_method.last4}</div>
                </div>
                <div className="flex justify-end gap-2 items-center text-sm">
                  <span>Update</span>
                  <i className="text-xs fa-regular fa-pen-to-square ml-2" />
                </div>
              </div>
            </div>
          )}
        </div>
        {!onboarding && <CancelSubscription />}
      </div>
    </div>
  );
};

export default withStuff(PaymentPlan, {
  api: true,
  state: true,
});
