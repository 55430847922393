import React, { useState } from "react";
import { withStuff } from "../hocs";
import { Form } from "../components";

const Dependents = ({ state, api, startWithSelf = false }) => {
  const dependents = state.dependents || [];
  const [self, setSelf] = useState(startWithSelf && dependents.length === 0);
  const [add, setAdd] = useState(startWithSelf && dependents.length === 0);

  return (
    <>
      {add ? (
        <>
          <div className="bg-move-lgrey rounded-md boder-solid border-move-teal border p-3">
            <div className="text-left mb-3 text-xl font-semibold text-move-teal">
              {self ? "Primary" : "Dependent"} Information
            </div>
            <Form
              col="12"
              submitCopy="Save"
              type="create_dependent"
              submitClass="bg-move-teal text-move-white rounded-full p-2 w-full mt-3"
              onSubmit={async (params) => {
                await api.createDependent(state.user.id, params);
                setAdd(false);
                setSelf(false);
              }}
              inputs={[
                {
                  type: "number",
                  label: "Age",
                  name: "age",
                },
                {
                  type: "radio",
                  label: "Sex",
                  name: "sex",
                  placeholder: "Select a sex",
                  options: ["Male", "Female"],
                },
                self
                  ? {
                      type: "hidden",
                      name: "myself",
                      defaultValue: true,
                    }
                  : {
                      type: "select",
                      name: "spouse",
                      label: "Dependent type",
                      options: {
                        yes: "Spouse",
                        no: "Child",
                      },
                    },
              ]}
            />
          </div>
        </>
      ) : (
        <button onClick={() => setAdd(true)} className="btn-primary w-full">
          Add a Dependent
        </button>
      )}
      {dependents.map((dependent, index) => (
        <div key={index} className="flex justify-between p-3 mt-3 bg-move-lgreg rounded-md w-full">
          <div>{dependent.name}</div>
          <div>{dependent.years_old}</div>
        </div>
      ))}
    </>
  );
};

export default withStuff(Dependents, {
  api: true,
  state: true,
  dependency: "user",
  loader: "dependents",
  effect: ({ api, state }) => {
    api.getDependents(state.user.id);
  },
});
