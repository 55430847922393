import React, { useState } from "react";
import { withStuff } from "../hocs";
import { Inputs } from "../components";

const Dependent = ({ self = false, index, spouseExists, ...props }) => {
  const [edit, setEdit] = useState(props.edit);
  const [dependent, setDependent] = useState({
    myself: index === 0,
    ...(props.dependent || {}),
  });

  const updateDependent = ({ target }) => {
    setDependent({
      ...dependent,
      [target.name]: target.value,
    });
  };

  const onSave = () => {
    setEdit(false);
    props.onSave({
      index,
      id: dependent.id,
      age: dependent.age,
      spouse: dependent.spouse == "Spouse",
      myself: dependent.myself,
      sex: dependent.sex,
    });
  };

  return (
    <>
      {edit ? (
        <>
          <div className="bg-move-lgrey flex-col text-right gap-4 justify-end rounded-md boder-solid border-move-teal border p-2">
            <Inputs
              col="12"
              inputs={[
                {
                  type: "number",
                  label: "Age",
                  name: "age",
                  defaultValue: dependent.age,
                  onChange: updateDependent,
                  required: self,
                },
                {
                  type: "radio",
                  label: "Sex",
                  name: "sex",
                  options: ["Male", "Female"],
                  defaultValue: dependent.sex,
                  onChange: updateDependent,
                  required: self,
                },
                self
                  ? {
                      type: "hidden",
                      name: "myself",
                      defaultValue: true,
                    }
                  : spouseExists
                  ? null
                  : {
                      type: "radio",
                      name: "spouse",
                      label: "Dependent type",
                      onChange: updateDependent,
                      defaultValue: dependent.spouse ? "Spouse" : "",
                      options: ["Spouse", "Child"],
                    },
              ].filter(Boolean)}
            />
            <span
              className="text-right rounded-md bg-move-slate text-move-white text-sm font-base hover:shadow-md hover:text-move-lslate px-3 py-2"
              onClick={onSave}
            >
              Save Applicant
            </span>
          </div>
        </>
      ) : (
        <div className="text-sm font-semibold capitalize p-2 bg-move-lslate items-center flex justify-between my-2 rounded">
          <div className="flex gap-2 items-center justify-start">
            <i
              onClick={() => {
                if (window.confirm("Are you sure you want to delete this dependent?")) {
                  api.destroyDependent(dependent.id);
                  props.removeDependent(index);
                }
              }}
              className="text-xs fa-solid fa-times text-move-navy cursor-pointer"
            />
            <div className="font-semibold text-move-teal">
              {dependent.sex} ({dependent.age})
            </div>
          </div>
          <div className="flex justify-end text-right">
            <i
              className="text-xs text-move-navy fa-solid fa-pencil cursor-pointer"
              onClick={() => setEdit(true)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default withStuff(Dependent, { api: true });
