import React from "react";
import { Table, Avatar, Select } from "../components";
import { capitalize } from "../utils";
import { withStuff } from "../hocs";

const AdvisorIndex = ({ state, api }) => {
  const schema = {
    name: {
      label: "Name",
      children: ({ datum }) => {
        return (
          <a
            className="flex justify-start space-x-4 items-center cursor-pointer no-underline"
            href={`/profile/${datum.id}`}
          >
            <div className="lg:block hidden" style={{ width: "33px !important" }}>
              <Avatar
                user={datum}
                size={32}
                onClick={() => (window.location.href = `/profile/${datum.id}`)}
              />
            </div>
            <div>
              <div className="text-move-blue text-md font-semibold">{datum.name}</div>
            </div>
          </a>
        );
      },
    },
    email: {
      label: "Email",
    },
    role: {
      label: "Role",
      children: ({ datum }) => {
        if (datum.role === "admin") {
          return "Admin";
        } else {
          return (
            <Select
              defaultValue={datum.role}
              options={["admin", "member"]}
              capitalizeOptions={true}
              onChange={({ target }) => {
                if (target.value == "admin") {
                  api.updateUserRole(datum.id);
                }
              }}
            />
          );
        }
      },
    },
    destroy: {
      label: " ",
      children: ({ datum }) => {
        if (datum.role !== "admin") {
          return (
            <a
              className="text-red-500 hover:text-red-700 cursor-pointer mr-5"
              onClick={() => {
                if (window.confirm("Are you sure you want to remove this advisor?"))
                  api.destroyUser(datum.id);
              }}
            >
              <i className="fa-solid fa-trash cursor-pointer" />
            </a>
          );
        } else {
          return null;
        }
      },
    },
  };

  if (state.users?.length === 0) {
    return null;
  }

  return (
    <>
      <div className="flex justify-between items-center mb-3">
        <h2 className="text-lg font-medium">Advisors</h2>
        <a
          className="rounded-md bg-move-orange text-move-white text-sm font-bold hover:shadow-md hover:text-move-lslate px-3 py-2"
          href="/advisors/new"
        >
          <button type="button">
            <i className="text-md fa-solid fa-circle-plus mr-2"></i>
            Invite
          </button>
        </a>
      </div>
      <Table schema={schema} data={state.users || []} />
    </>
  );
};

export default withStuff(AdvisorIndex, {
  api: true,
  state: true,
  loader: "users",
  effect: ({ api, state }) => {
    api.getUsers({ type: ["AdvisorUser", "HealthAdvisorUser"], account_id: state.account.id });
  },
});
