import React, { useState, useEffect } from "react";
import { withStuff } from "../hocs";
import { Breadcrumb, EstimationDependents, Inputs, SimpleForm, Submit } from "../components";
import { Estimation } from ".";

const stateMarkets = [
  "CA", // California
  "CO", // Colorado
  "CT", // Connecticut
  "DC", // District of Columbia
  "GA", // Georgia
  "KY", // Kentucky
  "ME", // Maine
  "MD", // Maryland
  "MA", // Massachusetts
  "MN", // Minnesota
  "NV", // Nevada
  "NJ", // New Jersey
  "NM", // New Mexico
  "NY", // New York
  "PA", // Pennsylvania
  "RI", // Rhode Island
  "VT", // Vermont
  "VA", // Virginia
  "WA", // Washington
];

const Estimator = ({ state, api, rootEstimator = false }) => {
  const estimation = state.estimation || {};
  const counties = state.counties || [];
  const [dependents, setDependents] = useState(estimation.dependents || []);
  const [timeout, saveTimeout] = useState(null);
  const [zip, setZip] = useState(estimation.zip);

  const medicareEligible = (dependents || []).some((d) => d.age >= 65);
  const marketplaceEligible = (dependents || []).some((d) => d.age < 65);

  useEffect(() => {
    setDependents(estimation.dependents);
  }, [estimation.dependents]);

  useEffect(() => {
    if (timeout) clearTimeout(timeout);

    saveTimeout(
      setTimeout(() => {
        api.getCountiesByZip(zip);
      }, 500)
    );
  }, [zip]);

  return (
    <div className="font-move mt-3 mx-3 xl:mx-0 ml-5 xl:ml-0">
      <Breadcrumb self={rootEstimator ? "Root Estimator" : "New Estimation"} />
      <div className="xl:flex gap-3 mt-12 items-start divide-x divide-move-lslate">
        <div className="md:flex flex-col w-full xl:w-1/3">
          <div className="text-left mb-2 text-lg font-semibold text-move-teal">
            {rootEstimator ? "Your" : "Client"} Details
          </div>
          <SimpleForm
            className="mt-6"
            onSubmit={(params) => {
              if (estimation.id) {
                api.updateEstimation(estimation.id, {
                  ...params,
                  rootEstimator,
                  dependents_attributes: dependents,
                });
              } else {
                api.createEstimation({
                  ...params,
                  rootEstimator,
                  dependents_attributes: dependents,
                });
              }
            }}
          >
            <div className="w-full md:flex block mb-4">
              <div className="w-full">
                <Inputs
                  col="12"
                  inputs={[
                    {
                      label: "First name",
                      name: "client_first_name",
                      type: "text",
                      defaultValue: estimation.client_first_name,
                      required: true,
                    },
                    {
                      label: "Zip code",
                      name: "zip",
                      type: "text",
                      defaultValue: estimation.zip,
                      onChange: (e) => {
                        setZip(e.target.value);
                      },
                      required: true,
                    },
                    counties.length > 1
                      ? {
                          label: "County",
                          name: "county",
                          type: "radio",
                          placeholder: "Select a county",
                          capitalizeOptions: true,
                          options: counties,
                          defaultValue: estimation.county,
                          required: true,
                        }
                      : {
                          type: "hidden",
                          name: "county",
                          defaultValue: counties[0],
                        },
                    {
                      type: "hidden",
                      name: "state",
                      defaultValue: counties?.length ? estimation.state || state.state : "null",
                    },
                  ]}
                />
                {stateMarkets.includes(estimation.state || state.state) && (
                  <div className="bg-move-lgrey mb-4 rounded-md boder-solid border-move-teal border p-4">
                    Hey! This looks like a state based marketplace zip code.
                    <br />
                    <br />
                    <a
                      target="_blank"
                      href={rootEstimator ? "/root/kff-estimator" : "/kff-estimator"}
                    >
                      Click here to run your estimate through our state based marketplace estimator.
                    </a>
                    <br />
                    <br />
                    If you're quoting a “blended” household (Medicare and Pre-65) please run two
                    separate estimates for the best results.
                  </div>
                )}
                <div className="mb-4 ">
                  <div className="input-primary">
                    <label>Who is needing coverage?</label>
                  </div>
                  <EstimationDependents
                    dependents={dependents || []}
                    onUpdate={(dependents) => {
                      setDependents([...dependents]);
                    }}
                  />
                </div>
                {marketplaceEligible && (
                  <Inputs
                    col="12"
                    inputs={[
                      {
                        type: "number",
                        label: "How many people are in the tax household?",
                        tooltip: "This is the number of people on the tax return.",
                        name: "household_size",
                        placeholder: "Enter your household size",
                        defaultValue: estimation.household_size || dependents?.length || 0,
                        required: true,
                      },
                      {
                        type: "currency",
                        label: "Household income (MAGI)",
                        name: "income",
                        tooltip:
                          "Modified Adjusted Gross Income (MAGI) is adjusted gross income (AGI) plus any untaxed foreign income, Social Security benefits, and tax-exempt interest. For most people, its the same as their adjusted gross income (AGI).",
                        placeholder: "Enter your household income",
                        defaultValue: estimation.income,
                        required: true,
                      },
                    ]}
                  />
                )}
                {medicareEligible && (
                  <div className="mt-4 border-dotted border-t-2">
                    <div className="text-md font-semibold pt-4">Medicare Information</div>
                    <Inputs
                      col="12"
                      inputs={[
                        {
                          type: "text",
                          label: "2023 Tax filing status",
                          name: "tax_filing_status",
                          defaultValue: estimation.tax_filing_status,
                          required: true,
                          type: "radio",
                          options: {
                            single: "Single",
                            married_joint: "Married - Joint",
                            married_seperate: "Married - Separate",
                          },
                        },
                        {
                          type: "currency",
                          label: "2023 Household income (MAGI)",
                          tooltip:
                            "Modified Adjusted Gross Income (MAGI) is adjusted gross income (AGI) plus any untaxed foreign income, Social Security benefits, and tax-exempt interest. For most people, its the same as their adjusted gross income (AGI).",
                          name: "last_year_income",
                          defaultValue: estimation.last_year_income,
                          required: true,
                        },
                      ]}
                    />
                  </div>
                )}
              </div>
            </div>
            {estimation.id ? (
              <Submit copy="Update" type="update_estimation" />
            ) : (
              <Submit copy="Save" type="update_estimation" />
            )}
          </SimpleForm>
        </div>
        <div className="xl:w-2/3 pl-5 w-full">
          <Estimation />
        </div>
      </div>
    </div>
  );
};

export default withStuff(Estimator, {
  api: true,
  state: true,
  loader: "estimation",
  effect: async ({ api, match, rootEstimator }) => {
    if (match.params.id) {
      api.getEstimation(match.params.id, !rootEstimator);
    }
  },
});
